import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {

  // Auswahlkriterien Variablen
  kliniken: string[] = ['Klinik X - Sto A', 'Klinik X - Sto B', 'Klinik X - Sto C'];
  icdDiagnosen: string[] = ['ICD-10-Diagnose 1', 'ICD-10-Diagnose 2'];
  weitereDiagnosen: string[] = ['Diagnose 1', 'Diagnose 2'];
  stationen: string[] = ['Station 1', 'Station 2'];
  einweisungen: string[] = ['Einweisung 1', 'Einweisung 2'];
  verweildauerOptionen: string[] = ['1 Tag', '1-5 Tage', '6-10 Tage', '11-20 Tage', '>20 Tage'];

  // Auswahlkriterien aus den Dropdowns
  selectedKlinik: string[] = [];
  selectedICD: string[] = [];
  selectedWeitereDiagnosen: string[] = [];
  selectedStationen: string[] = [];
  selectedEinweisung: string[] = [];
  selectedVerweildauer: string[] = [];

  // Download-Konsole Daten
  filteredPaths!: MatTableDataSource<any>;
  displayedColumns: string[] = ['sodiaphId', 'klinik', 'icdAufnahmeDiagnose', 'weitereDiagnosen', 'station', 'verweildauer', 'auswahl'];

  constructor(private liveAnnouncer: LiveAnnouncer, public router: Router, public generalService: GeneralService) { }

  ngOnInit(): void {
    this.generalService.setTitle('Downloads');
  }

  onPruefenClick(): void {
    // todo Logik 
    this.filteredPaths = this.fetchFilteredPaths();
  }

  fetchFilteredPaths(): MatTableDataSource<any> {
    return new MatTableDataSource<any>([
      { sodiaphId: '123', klinik: 'Klinik X - Sto A', icdAufnahmeDiagnose: 'ICD-10-Diagnose 1', weitereDiagnosen: 'Diagnose 1', station: 'Station 1', verweildauer: '5 Tage', selected: true },
      { sodiaphId: '124', klinik: 'Klinik X - Sto B', icdAufnahmeDiagnose: 'ICD-10-Diagnose 2', weitereDiagnosen: 'Diagnose 2', station: 'Station 2', verweildauer: '3 Tage', selected: true }
    ]);
  }

  toggleAll(event: any): void {
    const checked = event.checked;

    const filteredPaths = this.filteredPaths.data;

    if (filteredPaths && Array.isArray(filteredPaths)) {
      filteredPaths.forEach(path => path.selected = checked);
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }
}