import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdminService } from './api/admin.service';
import { AdmissionTypeService } from './api/admissionType.service';
import { AuthService } from './api/auth.service';
import { DataQualityService } from './api/dataQuality.service';
import { DataTransferService } from './api/dataTransfer.service';
import { DiagnosticService } from './api/diagnostic.service';
import { EcmService } from './api/ecm.service';
import { FacultyService } from './api/faculty.service';
import { HisService } from './api/his.service';
import { HisEntryService } from './api/hisEntry.service';
import { IcdService } from './api/icd.service';
import { InformationCarrierService } from './api/informationCarrier.service';
import { MandantService } from './api/mandant.service';
import { PatientArchiveService } from './api/patientArchive.service';
import { PatientPathService } from './api/patientPath.service';
import { QualityStatusService } from './api/qualityStatus.service';
import { StateService } from './api/state.service';
import { UnitService } from './api/unit.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdminService,
    AdmissionTypeService,
    AuthService,
    DataQualityService,
    DataTransferService,
    DiagnosticService,
    EcmService,
    FacultyService,
    HisService,
    HisEntryService,
    IcdService,
    InformationCarrierService,
    MandantService,
    PatientArchiveService,
    PatientPathService,
    QualityStatusService,
    StateService,
    UnitService,
    UserService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
