import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map, Observable, startWith, Subject, switchMap } from 'rxjs';
import { AdmissionTypeService, IcdService, PatientPathService, PatientPath, PatientPathCreateRequest, PatientPathDTO, UnitService, TreatmentUnitDTO, TreatmentUnitCreateRequest, DiagnosisCreateRequest, ICD, TreatmentUnitUpdateRequest, UnitDTO, AdmissionType } from 'src/swagger-typescript';
import { GeneralService } from '../general.service';
import { FormControl, NgForm } from '@angular/forms';
import { PathService } from '../path.service';
import { DatePipe, registerLocaleData } from '@angular/common';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import localeDe from '@angular/common/locales/de';
import { MatChipInputEvent } from '@angular/material/chips';

// Register the 'de-DE' locale data.
registerLocaleData(localeDe);

@Component({
  selector: 'app-new-path',
  templateUrl: './new-path.component.html',
  styleUrls: ['./new-path.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' } // 'de-DE' for German locale
  ]
})

export class NewPathComponent implements OnInit, AfterViewInit {

  separatorKeysCodes: number[] = [13, 188]; // Example separator key codes (Enter and comma (was 188))

  /** needed to dynamicylly add forms */
  @ViewChildren('editForm') formList: QueryList<NgForm> | undefined;

  /** path */
  public genders: PatientPath.GenderEnum[] = ['m', 'w', 'd'];
  public admissionTypes = [] as Array<AdmissionType>;
  public icds$!: Observable<ICD[]>;
  // Initialize searchTerms as a Subject
  private searchTerms: Subject<string> = new Subject<string>();
  public showAMD: boolean = false;
  public showSide: boolean = false;
  public showAD: boolean = false;

  /** units array for dropdown */
  public units = [] as Array<UnitDTO>;

  /** array of additional manindiagnoses */
  public additionalMainDiagnosesArray: Array<ICD> = [];

  /** array of additional manindiagnoses */
  public additionalSideDiagnosesArray: Array<ICD> = [];

  /** array of admission diagnoses */
  public admissionDiagnosesArray: Array<ICD> = [];

  /** empty path object to save path by id */
  public path: any = {};

  /** array of PatientPathDTO to share path between path overview an new path component */
  private data: PatientPathDTO[] = [];

  /** date pipe to format date */
  pipe = new DatePipe('de-DE');

  /** id from path that was created */
  private idPath: string = '';

  /** id from tratment unit to edit */
  public treatmentUnitId: string = '';

  /** id from treatment unit that was edited */
  public editedTreatmentUnitId: string = '';
  public editedInputs: { [key: string]: boolean } = {};
  public editedByPencil: boolean = false;

  /** empty pathUnits object */
  public pathUnits: any = {};

  /** array of TreatmentUnitDTO to render treatment units */
  public pathUnitsDTO: TreatmentUnitDTO[] = [];

  private diagnoses: Array<DiagnosisCreateRequest> = []

  public numberAfterSFID = 1;

  /** treatmentUnitSFID */
  /** array of TreatmentUnitDTO to render treatment units */
  public pathUnitsBySFIdDTO: TreatmentUnitDTO[] = [];
  public pathUnitsBySFID: string[] = []; // Array of SFID values
  public pathUnitsBySFIDObjects: any[] = []; // Array to hold the pathUnit objects
  public pathUnitCard: any = {};
  public showCreateNewSFID = false;

  public mainDiagnosesCtrl = new FormControl('');
  public sideDiagnosesCtrl = new FormControl('');
  public adDiagnosesCtrl = new FormControl('');

  @ViewChild('mainDiagInput')
  public mainDiagInput!: ElementRef<HTMLInputElement>;

  @ViewChild('sideDiagInput')
  public sideDiagInput!: ElementRef<HTMLInputElement>;

  @ViewChild('adDiagInput')
  public adDiagInput!: ElementRef<HTMLInputElement>;
  public noAdmissionDiagnosesSelected = true;

  showPopup = false;
  showDeleteSFID = false;
  showDeleteUnit = false;
  showInfo = false;
  popupText = '';

  /** check if user input was saved */
  public formSaved: boolean = false;

  /** check if new patient was saved */
  public newPatient: boolean = false;

  @ViewChild('pathForm')
  pathForm!: NgForm;

  SFIDtoDelete = '';
  unitToDelete = '';

  // station mat-form-field
  unitCtrl = new FormControl();
  filteredOptions!: Observable<UnitDTO[]>;
  selectedUnitId: string = '';

  /** used to prevent popup on keydown for admission date changes */
  admissionDateIsFocused: boolean = false;

  /** text fot comment function */
  freetextValue: string[] = []

  /** used to show more diagnoses name on click */
  showFullDiagnosis = false;

  constructor(private unitService: UnitService,
    public ownPathService: PathService,
    public router: Router,
    private generalService: GeneralService,
    private admissionTypeService: AdmissionTypeService,
    private icdService: IcdService,
    private patientPathService: PatientPathService) {
    this.path.gender = '';
    this.path.admissionTypeId = '';
  }

  ngOnInit(): void {

    this.filteredOptions = this.unitCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.generalService.setTitle('Patientenpfad')

    this.admissionTypeService.v1AdmissionTypeGet().subscribe(data => {
      this.admissionTypes = data;
    });

    this.unitService.v1UnitGet().subscribe(data => {
      this.units = data;
    });

    this.icds$ = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),
      // ignore new term if same as previous term
      distinctUntilChanged(),
      // switch to new search observable each time the term changes
      switchMap((term: string) => this.icdService.v1IcdSearchGet(term)),
    );

    //check if add new path button was clicked
    if (this.ownPathService.getisNewPath()) {
      return;
    }

    // load data if path on table was clicked
    this.data = this.ownPathService.getPath();
    if (this.data) {
      // reset opacity of sid input to 1
      const sidInput = document.getElementById("sodiaphID");
      if (sidInput) {
        sidInput.style.opacity = "1";
        // sidInput.style.backgroundColor = "#83A0B7";
        sidInput.style.color = "#6b8294";
        sidInput.style.border = "none";
      }
      const mainCard = document.getElementById("mainCard");
      if (mainCard) {
        mainCard.hidden = false;
      }
      this.path = this.data;
      this.generalService.setSodiaphID(this.path.SID);
      this.path.admissionDate = this.pipe.transform(this.path.admissionDate, 'yyyy-MM-dd');
      this.generalService.setAdmissionDate(this.path.admissionDate);
      if (this.path.id) {
        this.idPath = this.path.id;
      }
      if (this.path.diagnoses) {
        // Using forEach method
        this.path.diagnoses.forEach((diag: { type: string, code: string, name: string }) => {
          if (diag.type == 'MAIN') {
            const main: ICD = { code: diag.code, name: diag.name };
            this.additionalMainDiagnosesArray.push(main);
          } else if (diag.type == 'ADMISSION') {
            const ad: ICD = { code: diag.code, name: diag.name };
            this.admissionDiagnosesArray.push(ad);
            this.noAdmissionDiagnosesSelected = false;
          } else if ((diag.type == 'SIDE')) {
            const side: ICD = { code: diag.code, name: diag.name };
            this.additionalSideDiagnosesArray.push(side);
          }
        });
      }

      this.setUpTreatmentUnits();

    }

  }

  private _filter(value: string): UnitDTO[] {
    const filterValue = value.toLowerCase();
    return this.units.filter(option => option.name?.toLowerCase().includes(filterValue));
  }

  ngAfterViewInit() {
    this.formList!.forEach((form, index) => {
      form.name = `editForm${index}`;
    });
  }

  events: string[] = [];

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }

  calculateAge(inputDate: string) {
    if (inputDate) {
      const birthDate = new Date(inputDate);
      const admissionDate = new Date(this.path.admissionDate);
      const ageDiff = admissionDate.getFullYear() - birthDate.getFullYear();
      if (admissionDate.getMonth() < birthDate.getMonth() || (admissionDate.getMonth() === birthDate.getMonth() && admissionDate.getDate() < birthDate.getDate())) {
        this.path.age = ageDiff - 1;
      } else {
        this.path.age = ageDiff;
      }
    }
  }

  selectedMain(event: MatAutocompleteSelectedEvent) {
    const code = event.option.value.code;
    const mainDiagnosis = event.option.value.name;
    this.addAdditionalMainDiagnoses(code, mainDiagnosis, true);

    this.mainDiagInput.nativeElement.value = '';
    this.mainDiagnosesCtrl.setValue(null);
  }

  selectedSide(event: MatAutocompleteSelectedEvent) {
    const code = event.option.value.code;
    const sideDiagnosis = event.option.value.name;
    this.addAdditionalSideDiagnoses(code, sideDiagnosis, true);

    this.sideDiagInput.nativeElement.value = '';
    this.sideDiagnosesCtrl.setValue(null);
  }

  addAdditionalMainDiagnoses(code: string, mainDiagnosis: string, event: any) {
    const icd: ICD = { code: code, name: mainDiagnosis };
    if (typeof (event) === 'boolean') {
      this.additionalMainDiagnosesArray.push(icd);
    }
  }

  addAdditionalSideDiagnoses(code: string, mainDiagnosis: string, event: any) {
    const icd: ICD = { code: code, name: mainDiagnosis };
    if (typeof (event) === 'boolean') {
      this.additionalSideDiagnosesArray.push(icd);
    }
  }

  deleteMainDiagEntry(index: number) {
    this.additionalMainDiagnosesArray.splice(index, 1);
  }

  deleteSideDiagEntry(index: number) {
    this.additionalSideDiagnosesArray.splice(index, 1);
  }

  // Push a search term into the observable stream.
  searchMainDiagnoses(term: string): void {
    if (term.length >= 3) {
      this.searchTerms.next(term);
      if (this.showAMD == false) {
        this.showAMD = true;
      }
    }
    // close search when string is empty
    if (term.length == 0)
      this.showAMD = false;
  }

  // Push a search term into the observable stream.
  searchDiagnoses(term: string): void {
    if (term.length >= 3 || term.length == 0) {
      this.searchTerms.next(term);
      if (this.showSide == false) {
        this.showSide = true;
      }
    }
    // close search when string is empty
    if (term.length == 0)
      this.showSide = false;
  }

  /** Send path post request on submit button */
  onSubmit(form: NgForm) {

    // update path
    if (this.path && !this.ownPathService.getisNewPath() || this.path.SID) {

      // Check if any admission diagnoses are selected
      if (this.noAdmissionDiagnosesSelected) {
        this.popupText = 'Sie müssen eine Aufnahmediagnose auswählen';
        this.showPopup = true;
        return;
      }

      const genderForm = form.value.gender;
      const ageForm = form.value.age;
      const admissionDateForm = form.value.admissionDate;
      const admissionTypeIdForm = form.value.admissionTypeId;
      this.additionalMainDiagnosesArray.forEach((item) => {
        this.diagnoses.push(
          {
            code: item.code,
            type: 'MAIN',
            isMissingInDischargeLetter: false,
          });
      });

      this.additionalSideDiagnosesArray.forEach((item) => {
        this.diagnoses.push(
          {
            code: item.code,
            type: 'SIDE',
            isMissingInDischargeLetter: false,
          });
      });

      this.admissionDiagnosesArray.forEach((item) => {
        this.diagnoses.push(
          {
            code: item.code,
            type: 'ADMISSION',
            isMissingInDischargeLetter: false,
          });
      });

      const request: PatientPathCreateRequest = {
        gender: genderForm,
        age: ageForm,
        admissionDate: admissionDateForm,
        admissionTypeId: admissionTypeIdForm,
        diagnoses: this.diagnoses,
      }

      this.patientPathService.v1PatientPathIdPut(request, this.idPath).subscribe({
        next: (v) => { },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathIdPut " + e)
        },
        complete: () => { this.editedInputs[this.idPath] = false; }
      });

    } else {

      // Check if any admission diagnoses are selected
      if (this.noAdmissionDiagnosesSelected) {
        this.popupText = 'Sie müssen eine Aufnahmediagnose auswählen';
        this.showPopup = true;
        return;
      }

      // create Object Path
      const genderForm = form.value.gender;
      const ageForm = form.value.age;
      const admissionDateForm = form.value.admissionDate;
      const admissionTypeIdForm = form.value.admissionTypeId;

      this.additionalMainDiagnosesArray.forEach((item) => {
        this.diagnoses.push(
          {
            code: item.code,
            type: 'MAIN',
            isMissingInDischargeLetter: false,
          });
      });

      this.additionalSideDiagnosesArray.forEach((item) => {
        this.diagnoses.push(
          {
            code: item.code,
            type: 'SIDE',
            isMissingInDischargeLetter: false,
          });
      });

      this.admissionDiagnosesArray.forEach((item) => {
        this.diagnoses.push(
          {
            code: item.code,
            type: 'ADMISSION',
            isMissingInDischargeLetter: false,
          });
      });

      const request: PatientPathCreateRequest = {
        gender: genderForm,
        age: ageForm,
        admissionDate: admissionDateForm,
        admissionTypeId: admissionTypeIdForm,
        diagnoses: this.diagnoses,
      }

      this.patientPathService.v1PatientPathPost(request).subscribe({
        next: (v) => {
          const mainCard = document.getElementById("mainCard");
          if (mainCard) {
            mainCard.hidden = false;
            // set first sfid for unit cards
            this.pathUnits.SFID = v.SID + '_00' + this.numberAfterSFID;
            this.pathUnits.start = admissionDateForm;
            this.pathUnits.end = admissionDateForm;
          }
          if (v.id) {
            this.path = v;
            this.idPath = v.id;
            this.path.SID = v.SID;
            this.path.admissionDate = v.admission;

            this.ownPathService.setPath(this.path);
            this.generalService.setSodiaphID(this.path.SID);
            this.generalService.setAdmissionDate(this.path.admissionDate);

            // render sid after patient was created, reset opacity of sid input to 1
            const sidInput = document.getElementById("sodiaphID");
            if (sidInput) {
              sidInput.style.opacity = "1";
            }

          }
        },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPost " + e)
        },
        complete: () => { this.newPatient = true; this.ownPathService.isNewPathSubject.next(false); }
      });
    }
  }

  onSubmitUnitForm(form: NgForm) {

    // create Object Unit
    const locationForm = this.unitCtrl.value;
    const selectedUnit = this.units.find(option => option.name === locationForm);
    const selectedUnitId = selectedUnit?.id;

    const startDateForm = form.value.start;
    const endDateForm = form.value.end;
    const SFIDForm = form.value.SFID;

    if (this.pathUnitsBySFIDObjects.length >= 1) {
      this.numberAfterSFID = SFIDForm.split("_")[1];
    }

    const request: TreatmentUnitCreateRequest = {
      unitId: selectedUnitId,
      start: startDateForm,
      end: endDateForm,
      caseNumber: +this.numberAfterSFID,
    }

    this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitPost(request, this.idPath).subscribe({
      next: (v) => {
        this.setUpTreatmentUnits();
        this.showCreateNewSFID = false;
      },
      error: (e) => {
        let errorMessage = 'An error occurred.';
        if (e.error && e.error.errors && e.error.errors.length > 0) {
          errorMessage = e.error.errors[0].message;
        }
        this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
        this.showPopup = true;
        console.error("v1PatientPathPatientPathIdTreatmentUnitPost " + e)
      },
      complete: () => { this.unitCtrl.setValue(null); }
    });
  }

  getSelectedUnitName(unitId: string): string | undefined {
    const selectedUnit = this.units.find(unit => unit.id === unitId);
    return selectedUnit ? selectedUnit.name : '';
  }

  updateUnit(form: NgForm, id: string, formId: number) {
    // update units
    // create Object Unit
    const locationForm = form.value.unitId;
    const startDateForm = form.value.start;
    const endDateForm = form.value.end;
    const SFIDForm = form.value.SFID;

    const numberAfterSFID = SFIDForm.split("_")[1];

    const request: TreatmentUnitUpdateRequest = {
      unitId: locationForm,
      start: startDateForm,
      end: endDateForm,
      caseNumber: +numberAfterSFID,
    }


    this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut(request, this.idPath, id).subscribe({
      next: (v) => {
        const updatedUnit = this.pathUnitCard.units.find((unit: { id: any; }) => unit.id === id);
        if (updatedUnit) {
          updatedUnit.unitId = form.value.unitId;
          updatedUnit.start = form.value.start;
          updatedUnit.end = form.value.end;
        }
        this.editedTreatmentUnitId = '';
        this.setUpTreatmentUnits();
      },
      error: (e) => {
        let errorMessage = 'An error occurred.';
        if (e.error && e.error.errors && e.error.errors.length > 0) {
          errorMessage = e.error.errors[0].message;
        }
        this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
        this.showPopup = true;
        console.error("v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdPut " + e)
      },
      complete: () => { }
    });

  }

  deleteSFID(sfid: string) {
    if (this.idPath && sfid)
      this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDDelete(this.idPath, sfid).subscribe({
        next: (v) => {
          this.setUpTreatmentUnits();
        },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDDelete " + e)
        },
        complete: () => { }
      });
  }

  deleteUnit(treatmentUnitId: string) {
    if (this.idPath && treatmentUnitId)
      this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdDelete(this.idPath, treatmentUnitId).subscribe({
        next: (v) => {
          this.setUpTreatmentUnits();
        },
        error: (e) => {
          let errorMessage = 'An error occurred.';
          if (e.error && e.error.errors && e.error.errors.length > 0) {
            errorMessage = e.error.errors[0].message;
          }
          this.popupText = 'Error: ' + e.status + ' ' + errorMessage;
          this.showPopup = true;
          console.error("v1PatientPathPatientPathIdTreatmentUnitTreatmentUnitIdDelete " + e)
        },
        complete: () => { }
      });
    // Get pathUnit 
    const pathUnit = document.getElementById('pathUnit');
    // Hide the pathUnit
    if (pathUnit) {
      pathUnit.style.display = 'none';
    }
  }

  setUpTreatmentUnits() {

    // Convert start and end date from treatment unit
    this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitGet(this.idPath).subscribe((data: TreatmentUnitDTO[]) => {
      this.pathUnitsDTO = data;
      this.pathUnits = this.pathUnitsDTO;
      this.pathUnitsBySFID = []; // Clear the array before populating it again
      this.pathUnitsBySFIDObjects = []; // Clear the array before populating it again

      for (let item in this.pathUnits) {
        this.pathUnits[item].start = this.pipe.transform(this.pathUnits[item].start, 'yyyy-MM-dd');
        this.pathUnits[item].end = this.pipe.transform(this.pathUnits[item].end, 'yyyy-MM-dd');
        this.treatmentUnitId = this.pathUnits[item].id;
        // Check if the SFID is not already present in the pathUnitsBySFID array
        if (!this.pathUnitsBySFID.includes(this.pathUnits[item].SFID)) {
          this.pathUnitsBySFID.push(this.pathUnits[item].SFID);
        }
      }

      this.ownPathService.sfids = [];
      this.pathUnitsBySFID.forEach(sfid => {

        // set sfid for patient archive
        this.ownPathService.setSFIDs(sfid);

        this.patientPathService.v1PatientPathPatientPathIdTreatmentUnitSFIDSFIDGet(this.idPath, sfid).subscribe((data: TreatmentUnitDTO[]) => {
          this.pathUnitsBySFIdDTO = data;
          // Find the existing card with the matching SFID
          const existingPathUnitIndex = this.pathUnitsBySFIDObjects.findIndex(unit => unit.SFID === sfid);
          if (existingPathUnitIndex !== -1) {
            // Clear the existing units in the card
            this.pathUnitsBySFIDObjects[existingPathUnitIndex].units = [];
            // Append the new units to the existing card
            for (let item in this.pathUnitsBySFIdDTO) {
              const pathUnit: any = {
                unitId: this.pathUnitsBySFIdDTO[item].unitId,
                start: this.pipe.transform(this.pathUnitsBySFIdDTO[item].start, 'yyyy-MM-dd'),
                end: this.pipe.transform(this.pathUnitsBySFIdDTO[item].end, 'yyyy-MM-dd')
              };
              this.pathUnitsBySFIDObjects[existingPathUnitIndex].units.push(pathUnit);
            }
          } else {
            // Create a new card for the SFID and add the units
            this.pathUnitCard = {
              SFID: sfid,
              units: []
            };
            for (let item in this.pathUnitsBySFIdDTO) {
              const pathUnit: any = {
                id: this.pathUnitsBySFIdDTO[item].id,
                unitId: this.pathUnitsBySFIdDTO[item].unitId,
                start: this.pipe.transform(this.pathUnitsBySFIdDTO[item].start, 'yyyy-MM-dd'),
                end: this.pipe.transform(this.pathUnitsBySFIdDTO[item].end, 'yyyy-MM-dd')
              };
              this.pathUnitCard.units.push(pathUnit);
            }
            // Add the card to the pathUnitsBySFIDObjects array
            this.pathUnitsBySFIDObjects.push(this.pathUnitCard);
            // sort by asc
            this.pathUnitsBySFIDObjects = this.pathUnitsBySFIDObjects.slice().sort((a, b) => a.SFID.localeCompare(b.SFID));


            // set start date of new sfid to end date of last station of last sfid
            const dates: string[] = [];
            for (let i = 0; i < this.pathUnitsBySFIDObjects.length; i++) {
              for (let unit of this.pathUnitsBySFIDObjects[i].units) {
                dates.push(unit.end);
              }
            }

            // Convert the date strings to Date objects
            const dateObjects: Date[] = dates.map(dateString => new Date(dateString));
            // Sort the Date objects in descending order
            dateObjects.sort((a, b) => b.getTime() - a.getTime());
            // The first element in the sorted array will be the latest date
            const latestDate: Date = dateObjects[0];

            // // default values 
            for (let card of this.pathUnitsBySFIDObjects) {
              card.start = this.pipe.transform(latestDate, 'yyyy-MM-dd');
              card.end = this.pipe.transform(latestDate, 'yyyy-MM-dd');
            }

          }
        });
      });
    });
  }

  /** Add new SFID case */
  addSFID() {
    this.showCreateNewSFID = true;
    var numberAfterSFID = this.pathUnitsBySFIDObjects[this.pathUnitsBySFIDObjects.length - 1]["SFID"].split("_")[1];

    var newSFID = ""
    numberAfterSFID = Number(numberAfterSFID) + 1
    if (numberAfterSFID < 10) {
      newSFID = "00" + numberAfterSFID.toString()
    }
    else if (numberAfterSFID < 100) {
      newSFID = "0" + numberAfterSFID.toString()
    }
    else if (numberAfterSFID < 1000) {
      newSFID = numberAfterSFID.toString()
    }

    this.pathUnits.SFID = this.pathUnitsBySFIDObjects[this.pathUnitsBySFIDObjects.length - 1]["SFID"].split("_")[0] + "_" + newSFID;

    // set start date of new sfid to end date of last station of last sfid
    const dates: string[] = [];
    for (let i = 0; i < this.pathUnitsBySFIDObjects.length; i++) {
      for (let unit of this.pathUnitsBySFIDObjects[i].units) {
        dates.push(unit.end);
      }
    }
    // Convert the date strings to Date objects
    const dateObjects: Date[] = dates.map(dateString => new Date(dateString));
    // Sort the Date objects in descending order
    dateObjects.sort((a, b) => b.getTime() - a.getTime());
    // The first element in the sorted array will be the latest date
    const latestDate: Date = dateObjects[0];

    this.pathUnits.start = this.pipe.transform(latestDate, 'yyyy-MM-dd');

    this.pathUnits.unitId = '';
  }

  saved(click: boolean) {
    this.formSaved = click;
  }

  checkFormSaved() {
    // check if path form was saved or if it is empty
    if (this.formSaved || !this.pathForm.valid) {
      this.router.navigate(['/paths-overview']);
    }
    this.popupText = "Ihre Daten wurden noch nicht gespeichert.";
    this.showPopup = true;
  }

  reallyDeleteSFID() {
    this.deleteSFID(this.SFIDtoDelete);
  }

  reallyDeleteUnit() {
    this.deleteUnit(this.unitToDelete);
  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;
    this.showDeleteSFID = false
    this.showDeleteUnit = false;
    this.showInfo = false;
  }

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  setEndDateToStartDate() {
    this.pathUnits.end = this.pathUnits.start;
    for (let card of this.pathUnitsBySFIDObjects) {
      card.end = card.start;
    }
  }

  deleteADDiagEntry(index: number) {
    this.admissionDiagnosesArray.splice(index, 1);
    // Update the flag
    this.checkAdmissionDiagnosesSelected();
  }

  searchAdDiagnoses(term: string): void {
    if (term.length >= 3) {
      this.searchTerms.next(term);
      if (this.showAD == false) {
        this.showAD = true;
      }
    }
    // close search when string is empty
    if (term.length == 0)
      this.showAD = false;
  }

  checkAdmissionDiagnosesSelected() {
    this.noAdmissionDiagnosesSelected = this.admissionDiagnosesArray.length === 0;
  }

  addAdmissionDiagnoses(code: string, adDiagnosis: string, event: any) {
    const icd: ICD = { code: code, name: adDiagnosis };
    if (typeof (event) === 'boolean') {
      this.admissionDiagnosesArray.push(icd);
      // Update the flag
      this.checkAdmissionDiagnosesSelected();
    }

  }

  selectedAd(event: MatAutocompleteSelectedEvent) {
    const code = event.option.value.code;
    const adDiagnosis = event.option.value.name;
    this.addAdmissionDiagnoses(code, adDiagnosis, true);

    this.adDiagInput.nativeElement.value = '';
    this.adDiagnosesCtrl.setValue(null);
  }

  onAdmissionDateChange(newAdmissionDate: Date) {
    this.editedInputs[this.path.id] = true;
    this.admissionDateHasChanged(newAdmissionDate);
  }

  /** Triggered when focus is lost on admission date panel
   * Will generate new date from the field input and trigger admissionDateHasChanged afterwards
   */
  onAdmissionDateFocusOut(event: Event) {
    this.admissionDateIsFocused = false;
    const element = event.currentTarget as HTMLInputElement
    const value = element.value
    const parts = value.split('.');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Subtract 1 because months are zero-based
    const year = parseInt(parts[2], 10);

    this.admissionDateHasChanged(new Date(year, month, day));
  }

  onAdmissionDateFocusIn(event: Event) {
    this.admissionDateIsFocused = true;
  }

  admissionDateHasChanged(newAdmissionDate: Date) {
    //check if add new path button was clicked
    if (this.ownPathService.getisNewPath() || this.admissionDateIsFocused == true) {
      return;
    }

    const dates: string[] = [];
    for (let i = 0; i < this.pathUnitsBySFIDObjects.length; i++) {
      for (let unit of this.pathUnitsBySFIDObjects[i].units) {
        dates.push(unit.end);
      }
    }
    // Convert the date strings to Date objects
    const dateObjects: Date[] = dates.map(dateString => new Date(dateString));
    // Sort the Date objects in descending order
    dateObjects.sort((a, b) => b.getTime() - a.getTime());
    // The first element in the sorted array will be the latest date
    const latestDate: Date = dateObjects[0];

    if (newAdmissionDate > latestDate && newAdmissionDate !== null) {

      this.popupText = "Sie haben das Aufnahmedatum in der Klinik geändert und bereits Stationen im Patientenpfad erfasst. Bitte beachten Sie, dass das neue Aufnahmedatum nun zeitlich nach dem ersten Tag auf einer Station liegt. Sie müssen Änderungen an den Stationsdaten vornehmen, um eine gute Datenqualität sicherzustellen. Gleiches gilt für bereits erfolgte Einträge KIS und das Patientenarchiv!";
      this.showPopup = true;
    } else {
      this.popupText = "Sie haben das Aufnahmedatum in der Klinik geändert und bereits Stationen im Patientenpfad erfasst. Bitte beachten Sie, dass Sie hier ggfs. auch Änderungen vornehmen müssen um eine gute Datenqualität sicherzustellen. Gleiches gilt für bereits erfolgte Einträge KIS und das Patientenarchiv!";
      this.showPopup = true;
    }

  }

  handleUnsavedChanges() {
    if (this.editedInputs[this.path.id] == true) {
      console.log(this.editedInputs[this.path.id])
      this.popupText = 'Sie haben nicht gespeicherte Änderungen. Bitte speichern sie ihre Änderungen vor dem Verlassen der Seite.';
      this.showPopup = true;
    }
  }

}