<div style="display: flex;">
    <!-- Linker Frame: Auswahlkriterien -->
    <mat-card class="custom-outline-card" style="flex: 1; margin: 2%; margin-top: 2%; max-width: 400px;">
        <div class="custom-outline-label">Auswahlkriterien</div>
        <mat-card-content style="display: flex; flex-direction: column;">

            <mat-label>Klinik</mat-label>
            <mat-form-field style="margin-bottom: 5%;">
                <mat-select [(value)]="selectedKlinik" multiple>
                    <mat-option *ngFor="let klinik of kliniken" [value]="klinik">{{klinik}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-label>ICD-10-Aufnahme-Diagnose</mat-label>
            <mat-form-field style="margin-bottom: 5%;">
                <mat-select [(value)]="selectedICD" multiple>
                    <mat-option *ngFor="let icd of icdDiagnosen" [value]="icd">{{icd}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-label>Weitere Diagnosen</mat-label>
            <mat-form-field style="margin-bottom: 5%;">
                <mat-select [(value)]="selectedWeitereDiagnosen" multiple>
                    <mat-option *ngFor="let diagnose of weitereDiagnosen" [value]="diagnose">{{diagnose}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-label>Enthaltene Stationen</mat-label>
            <mat-form-field style="margin-bottom: 5%;">
                <mat-select [(value)]="selectedStationen" multiple>
                    <mat-option *ngFor="let station of stationen" [value]="station">{{station}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-label>Art der Einweisung</mat-label>
            <mat-form-field style="margin-bottom: 5%;">
                <mat-select [(value)]="selectedEinweisung" multiple>
                    <mat-option *ngFor="let einweisung of einweisungen" [value]="einweisung">{{einweisung}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-label>Verweildauer in Tagen</mat-label>
            <mat-form-field style="margin-bottom: 5%;">
                <mat-select [(value)]="selectedVerweildauer" multiple>
                    <mat-option *ngFor="let dauer of verweildauerOptionen" [value]="dauer">{{dauer}}</mat-option>
                </mat-select>
            </mat-form-field>

        </mat-card-content>

        <mat-card-actions>
            <button class="button_individual"
                style="margin-left: 68%; font-size: x-small; width: 30%; padding: 2%; margin-bottom: 4%;" mat-button
                (click)="onPruefenClick()">AUSWAHL PRÜFEN</button>
        </mat-card-actions>
    </mat-card>

    <!-- Rechter Frame: Download-Konsole -->
    <mat-card id="summary-card" style="flex: 1; margin-top: 2%; margin-right: 2%; margin-bottom: 2%;">
        <mat-card-content id="summary-card-content" style="overflow-y: auto; overflow-x: auto; color: #6b8294;">
            <table mat-table [dataSource]="filteredPaths" class="mat-elevation-z8" matSort
                (matSortChange)="announceSortChange($event)" matSortActive="SID" matSortDirection="asc"
                matSortDisableClear>
                <!-- Tabellenüberschriften -->
                <ng-container matColumnDef="sodiaphId">
                    <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef mat-sort-header="sodiaphId"
                        sortActionDescription="Sort by sodiaphId">SODIAPH-ID</th>
                    <td mat-cell *matCellDef="let element">{{element.sodiaphId}}</td>
                </ng-container>

                <ng-container matColumnDef="klinik">
                    <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef>Klinik (inkl. Standort)
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.klinik}}</td>
                </ng-container>

                <ng-container matColumnDef="icdAufnahmeDiagnose">
                    <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef>ICD-10 Aufnahmediagnose
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.icdAufnahmeDiagnose}}</td>
                </ng-container>

                <ng-container matColumnDef="weitereDiagnosen">
                    <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef>ICD-10 weitere Diagnosen
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.weitereDiagnosen}}</td>
                </ng-container>

                <ng-container matColumnDef="station">
                    <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef>1. Station des Aufenthalts
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.station}}</td>
                </ng-container>

                <ng-container matColumnDef="verweildauer">
                    <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef>Verweildauer in Tagen</th>
                    <td mat-cell *matCellDef="let element">{{element.verweildauer}}</td>
                </ng-container>

                <!-- Auswahl Checkbox -->
                <ng-container matColumnDef="auswahl">
                    <th style="background-color: #B9C8D2;" mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="toggleAll($event)">Auswahl</mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>