import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, Subscription } from 'rxjs'

import { LoggedInService } from 'src/app/logged-in.service';
//import { HttpServerErrorInterceptor } from "src/app/http-server-error-interceptor.service"
import { UserService } from 'src/swagger-typescript';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'sodiaph_webtool';

  /** used to indicate if popup is shown */
  public showPopup: boolean = false;

  /** text which is shown on popup */
  public popupText: string = "";

  /** Subscription for Errors from the DB-conenction */
  private errorSubscription: Subscription;

  /** Subscription for sessionto expire*/
  private sessionSubscription: Subscription;

  constructor(public router: Router,
    private userService: UserService,
    private loggedInService: LoggedInService,
    //private httpServerErrorInterceptor: HttpServerErrorInterceptor,
  ) {
    this.errorSubscription = Subscription.EMPTY
    /** subscribe session about to expire without handling next, error, complete */
    this.sessionSubscription = new Subscription();
  }

  /** Lifecycle hook on page init */
  ngOnInit() {

    // set timer on app start to automatically logout after 60 minutes (reset if login is performed in auth component)
    this.loggedInService.startSessionTimer();

    // navigate to login page, if server responds with status 401 to assessment request
    this.errorSubscription = this.loggedInService.errorItem$
      .subscribe(
        {
          next: (error) => {
            // dont show pop up when error is empty or when user role what checked
            if (error == null || this.loggedInService.appStarted == false) {
              return;
            }
            else if (error.status == 401) {
              this.popupText = "Login fehlgeschlagen. Falscher Nutzername oder falsches Passwort.";
              this.showPopup = true;
            }
            else if (error.status == 409) {
              this.popupText = "Diese Organisation wurde bereits angelegt. Bitte überprüfen Sie Ihre Eingabe oder kehren Sie ohne Speichern zur Übersicht zurück, um eine bestehende Organisation zu bearbeiten.";
              this.showPopup = true;
            }
            else {
              this.popupText = "Error - " + error.message;
              this.showPopup = true;
            }
          },
          error: (e) => console.error("errorSubscription error - " + e),
          complete: () => { }

        });

    // check if user is already logged in
    this.userService.v1UserOwnGet().pipe(catchError(() => EMPTY)).subscribe({
      next: (v) => {
        if (v.role != null) {
          this.loggedInService.setRole(v.role)
          this.loggedInService.loggedInSubject.next(true);
        }
        if (v.role == 'admin') {
          this.router.navigate(['./user-organization'])
        }
        else if (v.role == 'mandant') {
          this.router.navigate(['./paths-overview'])
        }
        else {
          this.router.navigate([''])
        }
      },
      error: (e) => { },
      complete: () => {
        if (this.loggedInService.appStarted == false) {
          this.loggedInService.setAppStarted(true);
        }
      }
    })

    // auto logout after 60 minutes of inactivity
    this.sessionSubscription = this.loggedInService.sessionAboutToExpire$.subscribe(() => {
      // show pop up
      this.popupText = "Sie werden wegen 60 Minuten Inaktivität vom System abgemeldet";
      this.showPopup = true;
      setTimeout(() => {
        // hide pop up after 5 seconds
        this.showPopup = false;
      }, 5000);
    });
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
    this.sessionSubscription.unsubscribe();
    this.loggedInService.loggedInSubject.unsubscribe();
  }

  /** Event triggered when clicking a button */
  answerEvent(eventStr: string) {
    this.popupText = "";
    this.showPopup = false;
  }

  sendEmail() {
    const subject = "Datenanfrage an SODIAPH";
    const body = encodeURIComponent(`Sehr geehrte Damen und Herren,
    für das <Projekt/Institut> möchte ich eine Anfrage zur Bereitstellung von Daten stellen.
    <Beschreiben Sie Ihren Datenbedarf hier möglichst präzise, so dass SODIAPH Ihre Anfrage beurteilen kann>
    Vielen Dank!
    Mit freundlichen Grüßen,
    <Name>`);
    window.location.href = `mailto:Stefanie.Lehmann@izi.fraunhofer.de?subject=${subject}&body=${body}`;
  }

  /** host listeners for mouse move and keydown events to reset sesstion timer*/
  // mouseX = 0;
  // mouseY = 0;
  // @HostListener('document:mousemove', ['$event'])
  // mousemove(event: Event) {
  //   if (event.type === 'mousemove') {
  //     const mouseEvent = event as MouseEvent;
  //     this.mouseX = mouseEvent.clientX;
  //     this.mouseY = mouseEvent.clientY;
  //   }
  //   this.resetUserOwn();
  // }

  // @HostListener('document:keydown', ['$event'])
  // keydown(event: Event) {
  //   if (event.type === 'keydown') {
  //     return;
  //   }
  //   this.resetUserOwn();
  // }

  // reset session timer and request to user own every 15 minutes
  // private resetUserOwn() {
  //   this.loggedInService.resetSessionTimer();
  //   if (!this.loggedInService.getAppStarted()) {
  //     return;
  //   }
  //   const timeElapsed = Date.now() - this.loggedInService.lastUserAction;
  //   if (timeElapsed > 900000) { // 15 minutes
  //     this.userService.v1UserOwnGet().subscribe({
  //       next: (v) => {
  //       },
  //       error: (e) => {
  //         console.error('error reset session: ' + e)
  //       },
  //       complete: () => { },
  //     })
  //   }
  // }

}
