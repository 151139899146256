export * from './admin.service';
import { AdminService } from './admin.service';
export * from './admissionType.service';
import { AdmissionTypeService } from './admissionType.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './dataQuality.service';
import { DataQualityService } from './dataQuality.service';
export * from './dataTransfer.service';
import { DataTransferService } from './dataTransfer.service';
export * from './diagnostic.service';
import { DiagnosticService } from './diagnostic.service';
export * from './ecm.service';
import { EcmService } from './ecm.service';
export * from './faculty.service';
import { FacultyService } from './faculty.service';
export * from './his.service';
import { HisService } from './his.service';
export * from './hisEntry.service';
import { HisEntryService } from './hisEntry.service';
export * from './icd.service';
import { IcdService } from './icd.service';
export * from './informationCarrier.service';
import { InformationCarrierService } from './informationCarrier.service';
export * from './mandant.service';
import { MandantService } from './mandant.service';
export * from './patientArchive.service';
import { PatientArchiveService } from './patientArchive.service';
export * from './patientPath.service';
import { PatientPathService } from './patientPath.service';
export * from './qualityStatus.service';
import { QualityStatusService } from './qualityStatus.service';
export * from './state.service';
import { StateService } from './state.service';
export * from './unit.service';
import { UnitService } from './unit.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AdminService, AdmissionTypeService, AuthService, DataQualityService, DataTransferService, DiagnosticService, EcmService, FacultyService, HisService, HisEntryService, IcdService, InformationCarrierService, MandantService, PatientArchiveService, PatientPathService, QualityStatusService, StateService, UnitService, UserService];
